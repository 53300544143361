import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout pageInfo={{ pageName: "about" }}>
    <SEO title="About" />
    <h1>About CoinMarketMood</h1>
    <p>CoinMarketMood shares a ranking of cryptocurrencies based on the sentiment about them on social media.</p>
    <h2>What exactly do we do?</h2>
    <p>We take data from social media platforms like Twitter and Medium and analyse the language being used by real people when they are talking about cryptocurrencies.</p>
    <p>Our algorithm checks the language people are using for various indicators of posititive and negative sentiment. We then work out a score for each cryptocurrency based on the average over a set duration fo time.</p>
    <h2>How does CoinMarketMood analyse the mood of cryptocurrencies?</h2>
    <p>We scour the web, looking for conversations about cryptocurrencies. We look for what people from all backgrounds are saying in places like <a target='_blank' rel='noopener noreferrer' href="https://www.reddit.com/">Reddit</a>, <a target='_blank' rel='noopener noreferrer' href="https://www.twitter.com/">Twitter</a>, <a target='_blank' rel='noopener noreferrer' href="https://www.medium.com/">Medium</a>, forums, blogs, etc, and perform our analysis to get the overall mood of each cryptocurrency.</p>
    <h2>How can you gauge the mood of all these conversations?</h2>
    <p>First, we have to have our system understand all these conversations. We use a mixture of rule based and machine learning based <a target='_blank' rel='noopener noreferrer' href="https://en.wikipedia.org/wiki/Natural_language_processing">Natural Language Processing (NLP)</a> techniques, which break language down into understandable pieces, labelling the <a target='_blank' rel='noopener noreferrer' href="https://en.wikipedia.org/wiki/Part_of_speech">parts of speech</a>, identifies the <a target='_blank' rel='noopener noreferrer' href="https://en.wikipedia.org/wiki/Dependency_grammar">structure of the text</a>, identifies important <a target='_blank' rel='noopener noreferrer' href="https://en.wikipedia.org/wiki/Named-entity_recognition">entities</a>, and more. These broken down language bits can then be used to <a target='_blank' rel='noopener noreferrer' href="https://en.wikipedia.org/wiki/Sentiment_analysis">analyse the sentiment</a> to see if people are talking favourably or negatively about the target currency.
    Our system can then detect the upward and downward trends as and when they happen, which means you can have better insights into where the market is heading.</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
